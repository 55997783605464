import React from 'react'
import { EuiCallOut, EuiText } from '@elastic/eui'

import FloatingGallery from '../../../../../components/Software/Android/InstarVision/Galleries/instarvision-android-recording-floating-gallery'

import NavButtons from '../../../../../components/Software/Android/InstarVision/NavButtons'

import SEOHelmet from '../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'Android App InstarVision',
  description: 'The Android app InstarVision for your Android tablet or smart phone. Control your camera when you don´t have access to your computer.',
  image: '/images/Search/P_SearchThumb_InstarVision_Android.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_Android.webp',
  locationEN: '/en/Software/Android/InstarVision/Recording/',
  locationDE:'/de/Software/Android/InstarVision/Recording/',
  locationFR:'/fr/Software/Android/InstarVision/Recording/'
}

function InstarVisionDDNS(props) {
  return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
    locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="Recording" />
          
      <NavButtons />
    
      <h1 css={{ marginTop: 0 }}>InstarVision Surveillance Center</h1>
      <h2>Android Software</h2>
      <br />
      <h3>Video Recording</h3>
      <p>Review your alarm and manual recordings on your phone and from your camera&apos;s SD card:</p>
      <br />
      <FloatingGallery />
      <br />
      <br />
      <EuiCallOut size="m" color="danger" iconType="cloudStormy" title="Please be Aware:">
        <EuiText>
          <p>When you connect to your INSTAR camera via P2P, you will not be able to access Snapshots on your SD card (accessing Videos will work).</p>
        </EuiText >
      </EuiCallOut>
    </React.Fragment>
  );
}

export default InstarVisionDDNS